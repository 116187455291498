<template>
  <div class="container">
    <img src="../assets/img/contactUs/contactUs.png" alt="LinkingInnovations" class="ContactUs-background">
    <section class="ContactUs-section">
      <div class="ContactUs-section-title">{{ $t('ContactUs.title') }}</div>
      <div class="ContactUs-section-content">
        <div class="ContactUs-section-content-left">
          <div class="ContactUs-section-content-left-card" v-for="(item, index) in companyInformationList" :key="index">
            <div class="ContactUs-section-content-left-title">{{ item.title }}</div>
            <div class="ContactUs-section-content-left-information">{{ item.data }}</div>
          </div>
        </div>
        <div class="ContactUs-section-content-right-div">
          <img class="ContactUs-section-content-right" src="../assets/img/contactUs/map.png"
            :alt="$t('Common.address')" />
          <div class="ContactUs-section-content-right-content">
            {{ $t('Common.mapAddress') }}
            <div class="ContactUs-section-content-right-content-triangle"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="ContactUs-section-subscription">
      <img class="ContactUs-section-subscription-left" src="../assets/img/contactUs/subscription.png"
        :alt="$t('common.companyName')">
      <div class="ContactUs-section-subscription-right">
        <div class="ContactUs-section-subscription-right-title">{{ $t('ContactUs.subscription') }}</div>
        <div class="ContactUs-section-subscription-right-purpose">{{ $t('ContactUs.purpose') }}</div>
        <a-form-model ref="ruleForm" :model="ruleForm" :rules="rules"
          class="ContactUs-section-subscription-right-form-information">
          <a-form-model-item :label="$t('ContactUs.name')" prop="name" class="ContactUs-section-subscription-right-form">
            <a-input v-model="ruleForm.name" class="ContactUs-section-subscription-right-form-input"
              :placeholder="$t('ContactUs.enterName')"></a-input>
          </a-form-model-item>
          <a-form-model-item :label="$t('ContactUs.hospital')" prop="hospital"
            class="ContactUs-section-subscription-right-form">
            <a-input v-model="ruleForm.hospital" class="ContactUs-section-subscription-right-form-input"
              :placeholder="$t('ContactUs.enterVisitingHospital')"></a-input>
          </a-form-model-item>
          <a-form-model-item :label="$t('ContactUs.phone')" prop="telephone"
            class="ContactUs-section-subscription-right-form">
            <a-input v-model="ruleForm.telephone" class="ContactUs-section-subscription-right-form-input"
              :placeholder="$t('ContactUs.enterTelephone')"></a-input>
          </a-form-model-item>
          <a-form-model-item :label="$t('ContactUs.mailbox')" prop="email"
            class="ContactUs-section-subscription-right-form">
            <a-input v-model="ruleForm.email" class="ContactUs-section-subscription-right-form-input"
              :placeholder="$t('ContactUs.enterMailbox')"></a-input>
          </a-form-model-item>
          <a-form-model-item :label="$t('ContactUs.information')" prop="note"
            class="ContactUs-section-subscription-right-form">
            <a-textarea v-model="ruleForm.note" class="ContactUs-section-subscription-right-form-textarea"
              :placeholder="$t('ContactUs.enterInformation')" :maxLength="500" />
            <div class="ContactUs-section-subscription-right-form-textarea-number">
              <span>{{ ruleForm.note.length }}</span>/500
            </div>
          </a-form-model-item>
          <a-form-model-item class="ContactUs-section-subscription-right-submit">
            <a-button class="submit" @click="submitForm('ruleForm')">{{ $t('ContactUs.submit') }}</a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ContactUs",
  data() {
    return {
      companyInformationList: [
        { title: this.$t('ContactUs.telephone-en'), data: this.$t('ContactUs.telephone') },
        { title: this.$t('ContactUs.email-en'), data: this.$t('ContactUs.email') },
        { title: this.$t('ContactUs.address-en'), data: this.$t('ContactUs.address') }
      ],
      ruleForm: {
        name: '',
        hospital: '',
        telephone: '',
        email: '',
        note: '',
      },
      rules: {
        name: [{ required: true, message: this.$t('ContactUs.enterName'), trigger: 'blur' }],
        hospital: [{ required: true, message: this.$t('ContactUs.enterVisitingHospital'), trigger: 'blur' }],
        telephone: [{ required: true, message: this.$t('ContactUs.enterTelephone'), trigger: 'blur' },
        {
          validator: (rule, value, callback) => {
            let telephoneTest = /^1[34578]\d{9}$/;
            let telephoneRegExp = new RegExp(telephoneTest);
            if (telephoneRegExp.test(value)) {
              callback();
            } else {
              callback(new Error(this.$t('ContactUs.enterCorrectTelephone')));
            }
          }
        }],
        email: [{ required: true, message: this.$t('ContactUs.enterMailbox'), trigger: 'blur' },
        {
          validator: (rule, value, callback) => {
            let emailTest = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
            let emailRegExp = new RegExp(emailTest);
            if (emailRegExp.test(value)) {
              callback();
            } else {
              callback(new Error(this.$t('ContactUs.enterCorrectMailbox')));
            }
          }
        }],
        note: [{ required: true, message: this.$t('ContactUs.enterInformationContent'), trigger: 'blur' }],
      },
      layout: {
        labelCol: { span: 4 },
      },
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<style scoped lang="less">
.ContactUs-background {
  display: block;
  width: 100%;
  height: 600px;
}

.ContactUs-section {
  width: 100%;
  padding: 0 240px;
  box-sizing: border-box;
}

.ContactUs-section-title {
  height: 78px;
  font-size: 40px;
  font-weight: 600;
  color: #FFFFFF;
  text-align: center;
  line-height: 78px;
  margin: 80px 0;
}

.ContactUs-section-content {
  display: flex;
  justify-content: center;
}

.ContactUs-section-content-left {
  display: flex;
  flex-direction: column;
  width: 400px;
}

.ContactUs-section-content-left-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  flex: 1;
  background: rgba(255, 255, 255, 0.08);
  text-align: left;
  padding: 0 40px 0 40px;
  margin-bottom: 2px;
}

.ContactUs-section-content-left-card:nth-last-child(1) {
  margin-bottom: 0;
}

.ContactUs-section-content-left-title {
  font-size: 24px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 33px;
}

.ContactUs-section-content-left-information {
  font-size: 20px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 28px;
}

.ContactUs-section-content-right-div {
  position: relative;
}

.ContactUs-section-content-right {
  display: block;
  height: 500px;
  width: 700px;
}

.ContactUs-section-content-right-content {
  position: absolute;
  width: 270px;
  height: 86px;
  background: #FFFFFF;
  left: 204px;
  font-size: 14px;
  border-radius: 8px;
  top: 140px;
  color: #333333;
  padding: 0 20px;
  line-height: 1.2;
  display: flex;
  align-items: center;
}

.ContactUs-section-content-right-content-triangle {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: -20px;
  width: 0;
  height: 0;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  border-top: 20px solid #FFFFFF;

}

.ContactUs-section-subscription {
  display: flex;
  width: 100%;
  padding: 160px 240px;
}

.ContactUs-section-subscription-left {
  width: 560px;
  height: 500px;
}

.ContactUs-section-subscription-right {
  margin-left: 88px;
}

.ContactUs-section-subscription-right-title {
  text-align: left;
  height: 78px;
  font-size: 40px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 78px;
  margin: 0 0 16px 0;
}

.ContactUs-section-subscription-right-purpose {
  text-align: left;
  height: 28px;
  font-size: 20px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.7);
  line-height: 28px;
  margin-bottom: 66px;
}

.ContactUs-section-subscription-right-form {
  position: relative;
  display: flex;
}

.ContactUs-section-subscription-right-form-information {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ContactUs-section-subscription-right-form-input {
  width: 320px;
  height: 38px;
  font-size: 16px;
  background: rgba(243, 243, 243, 0.1);
  border-radius: 2px;
  border: 0;
}

.ContactUs-section-subscription-right-form-textarea {
  width: 820px;
  height: 112px;
  background: rgba(243, 243, 243, 0.1);
  border-radius: 2px;
  color: #d6d6d6;
  font-size: 16px;
  resize: none;
  border: 0;
}

.ContactUs-section-subscription-right-form-textarea-number {
  position: absolute;
  right: 24px;
  bottom: 12px;
  font-size: 14px;
  font-weight: 400;
  color: #D6D6D6;
  opacity: 0.5;
}

.ContactUs-section-subscription-right-submit {
  width: 100%;
  text-align: right;
  margin-top: 4px;
}

.submit {
  width: 150px;
  height: 40px;
  background: linear-gradient(136deg, #24E8A6 0%, #07CBD5 100%);
  border-radius: 2px;
  font-size: 20px;
  color: #333333;
}

/deep/ .ant-form-item {
  margin-bottom: 30px;
  position: relative;
}

/deep/ .has-error .ant-form-explain,
.has-error .ant-form-split {
  position: absolute;
  text-align: left;
  font-size: 16px;
  margin-top: 2px;
}

/deep/ .ant-form-item label {
  display: block;
  height: 38px;
  font-size: 20px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 38px;
  white-space: nowrap;
  margin-right: 12px;
  text-align: left;
}

/deep/ .ant-form-item-label>label::after {
  display: none;
}

/deep/ .ant-form-item-label>label::before {
  display: none;
}

/deep/ .has-error .ant-input:focus {
  border: 0;
}

/deep/ .ant-form-explain,
.ant-form-extra {
  position: absolute;
  line-height: 1;
  color: #de1f26;
  font-size: 0;
}

@media screen and (max-width: 768px) {
  .ContactUs-background {
    display: block;
    width: 100%;
    height: 140px;
  }

  .ContactUs-section {
    width: 100%;
    padding: 0 12px;
    box-sizing: border-box;
  }

  .ContactUs-section-title {
    height: 30px;
    font-size: 14px;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
    line-height: 30px;
    margin: 20px 0;
  }

  .ContactUs-section-content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .ContactUs-section-content-left {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .ContactUs-section-content-left-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    background: rgba(255, 255, 255, 0.08);
    text-align: left;
    padding: 6px 4px;
    margin-bottom: 1px;
  }

  .ContactUs-section-content-left-title {
    font-size: 9px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 13px;
  }

  .ContactUs-section-content-left-information {
    font-size: 8px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 11px;
  }

  .ContactUs-section-content-right {
    display: block;
    height: 200px;
    width: 100%;
    margin-top: 4px;
  }

  .ContactUs-section-content-right-div {
    position: relative;
  }

  .ContactUs-section-content-right-content {
    position: absolute;
    width: 94px;
    height: 36px;
    background: #FFFFFF;
    left: 66px;
    border-radius: 2px;
    top: 60px;
    font-size: 6px;
    padding: 0 6px;
    display: flex;
    align-items: center;
    line-height: 1.2;
  }

  .ContactUs-section-content-right-content-triangle {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -8px;
    width: 0;
    height: 0;
    border-right: 4px solid transparent;
    border-left: 5px solid transparent;
    border-top: 8px solid #FFFFFF;

  }

  .ContactUs-section-subscription {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 12px;
  }

  .ContactUs-section-subscription-left {
    width: 100%;
    height: 100%;
  }

  .ContactUs-section-subscription-right {
    width: 100%;
    margin-left: 0;
  }

  .ContactUs-section-subscription-right-title {
    text-align: center;
    height: 16px;
    font-size: 14px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 16px;
    margin: 10px auto 8px;
  }

  .ContactUs-section-subscription-right-purpose {
    text-align: center;
    height: 14px;
    font-size: 12px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.7);
    line-height: 14px;
    margin-bottom: 10px;
  }

  .ContactUs-section-subscription-right-form {
    width: 100%;
    position: relative;
    display: flex;
  }

  .ContactUs-section-subscription-right-form-information {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .ContactUs-section-subscription-right-form-input {
    width: 100%;
    height: 15px;
    font-size: 8px;
    background: rgba(243, 243, 243, 0.1);
    border-radius: 2px;
    border: 0;
  }

  .ContactUs-section-subscription-right-form-textarea {
    width: 100%;
    height: 44px;
    background: rgba(243, 243, 243, 0.1);
    border-radius: 2px;
    color: #d6d6d6;
    font-size: 8px;
    resize: none;
    border: 0;
  }

  .ContactUs-section-subscription-right-form-textarea-number {
    position: absolute;
    right: 4px;
    bottom: 8px;
    font-size: 5px;
    font-weight: 400;
    color: #D6D6D6;
    opacity: 0.5;
  }

  .ContactUs-section-subscription-right-submit {
    width: 100%;
    text-align: center;
    margin-top: 34px;
  }

  .submit {
    width: 60px;
    height: 16px;
    background: linear-gradient(136deg, #24E8A6 0%, #07CBD5 100%);
    border-radius: 2px;
    font-size: 8px;
    color: #333333;
  }

  /deep/ .ant-form-item {
    margin-bottom: 14px;
    position: relative;
  }

  /deep/ .has-error .ant-form-explain,
  .has-error .ant-form-split {
    position: absolute;
    text-align: left;
    font-size: 8px;
    margin-top: 2px;
  }

  /deep/ .ant-form-item label {
    display: block;
    height: 11px;
    width: 35px;
    font-size: 8px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 11px;
    white-space: nowrap;
    margin-right: 0;
    text-align: left;
  }

  /deep/ .ant-form-item-label {
    width: 60px;
    height: 16px;
    line-height: 16px;
  }

  /deep/ .ant-form-item-control {
    line-height: 16px;
    height: 16px;
  }

  /deep/ .ant-form-item-label>label::after {
    display: none;
  }

  /deep/ .ant-form-item-label>label::before {
    display: none;
  }

  /deep/ .has-error .ant-input:focus {
    border: 0;
  }

  /deep/ .ant-form-explain,
  .ant-form-extra {
    position: absolute;
    line-height: 1;
    color: #de1f26;
    font-size: 0;
  }
}</style>